import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from 'gatsby';
import HeadHelmet from "../components/HeadHelmet/HeadHelmet";

const ErrorSendForm = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageWrapper
        headerConfig={{
          align: "right",
          button: "null", // cta, account, null
        }}
        footerConfig={{
          style: "style_snore"
        }}
      >
        <HeadHelmet
          title='error_title'
          description='error_title'
          //image={imgFeatured}
        />

        <div className="inner-banner pt-29 pb-md-11 bg-default-2">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col lg="9" xl="8">
                <div className="px-md-15 text-center">
                  <i className="gr-text-2 mb-9 fas fa-exclamation-circle text-red"></i>
                  <h1 className="title gr-text-4 mb-13 heading-color">
                    {t('error_title')}</h1>
                  <p className="gr-text-8 mb-10 text-color-opacity">
                    {t('error_b1')}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </PageWrapper>
    </>
  );
};
export default ErrorSendForm;
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
